import { useIsMounted } from "lib/hooks/use-is-mounted.hook";
import { CheckSubRoleProps } from "./check-sub-role.interface";
import { useUserRole } from "lib/hooks/use-user-role.hook";

export const CheckSubRole = ({
    subRoles,
    fallback,
    children,
}: CheckSubRoleProps) => {
    const isMounted = useIsMounted();
    const authUserSubRole = useUserRole();

    if (!isMounted) {
        return null;
    }

    if (subRoles.length === 0) {
        return <>{children}</>;
    }

    if (!authUserSubRole) {
        return null;
    }

    if (!subRoles.includes(authUserSubRole)) {
        return fallback || null;
    }

    return <>{children}</>;
};
