import { useCallback, useMemo } from "react";
import { MediaQuery, Menu } from "@mantine/core";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { LeftMenuComponentProps } from "./left-menu.interface";
import { breakpoints } from "lib/breakpoints";
import { useUserType } from "lib/hooks/use-user-type.hook";
import { useIsMounted } from "lib/hooks/use-is-mounted.hook";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { MenuButton } from "./components/menu-button/menu-button.component";
import { NavLink } from "./components/nav-link/nav-link.component";
import { generateTranslatedConfig } from "./left-menu-config";
import { UserType } from "lib/constants/user-type";
import { paths } from "lib/constants/paths";
import { CheckPermissions } from "components/atoms/check-permissions/check-permissions.component";
import {
    createSignInOnboardingTargetProps,
    SignInOnboardingTargets,
} from "components/providers/onboarding/sign-in";
import {
    StyledLeftMenu,
    StyledLogoContainer,
    StyledMenuContent,
    StyledManagementBlock,
    StyledManagementBlockText,
    StyledSubitems,
    StyledMobileBackgroundGrey,
    StyledMenuBlock,
    StyledManagementBlockDropdownText,
    StyledMenuDropdown,
} from "./left-menu.styled";
import { CheckSubRole } from "components/atoms/check-sub-role/check-sub-role.component";

export const LeftMenu = ({
    isActive,
    setIsActive,
    isMobile,
}: LeftMenuComponentProps) => {
    const router = useRouter();
    const { t: commonT } = useTranslation("common");

    const closeSideBar = useCallback(() => {
        if (isMobile) {
            setIsActive(false);
        }
    }, [isMobile, setIsActive]);

    const toggleActive = (event: React.MouseEvent) => {
        setIsActive(!isActive);
        event.stopPropagation();
    };

    const userType = useUserType();

    const isMounted = useIsMounted();

    const onLogoClick = () => {
        if (userType === UserType.B2B_USER) {
            void router.push(paths.STARTER_B2B);
            closeSideBar();
        }
    };

    const translatedConfig = useMemo(() => {
        if (!isMounted || !userType) {
            return [];
        }

        return generateTranslatedConfig(commonT)[userType];
    }, [isMounted, userType, commonT]);

    return (
        <>
            <MediaQuery
                query={`(min-width: ${`${breakpoints.lg - 1}px`})`}
                styles={{ display: "none" }}
            >
                <StyledMobileBackgroundGrey
                    isActive={isActive}
                    isMobile={isMobile}
                    onClick={closeSideBar}
                />
            </MediaQuery>

            <StyledLeftMenu
                isActive={isActive}
                isMobile={isMobile}
                {...createSignInOnboardingTargetProps(
                    SignInOnboardingTargets.LEFT_MENU,
                )}
            >
                <StyledLogoContainer onClick={onLogoClick} isActive={isActive}>
                    <Icon name={Icons.headerLogoTextSvg} />
                    <MenuButton
                        isActive={isActive}
                        toggleActive={toggleActive}
                    />
                </StyledLogoContainer>
                <StyledMenuBlock>
                    {translatedConfig.map((group, index) => (
                        <StyledMenuContent
                            key={group.name || index}
                            isActive={isActive}
                        >
                            <Menu
                                withinPortal
                                position="right-start"
                                offset={2}
                                trigger="hover"
                                disabled={isActive}
                            >
                                <Menu.Target>
                                    <StyledManagementBlock>
                                        {group.icon}
                                        <StyledManagementBlockText
                                            isActive={isActive}
                                            title={group.name}
                                        >
                                            {group.name}
                                        </StyledManagementBlockText>
                                    </StyledManagementBlock>
                                </Menu.Target>

                                <StyledMenuDropdown>
                                    <StyledManagementBlockDropdownText>
                                        {group.name}
                                    </StyledManagementBlockDropdownText>
                                    {group.links.map((link) => (
                                        <CheckPermissions
                                            key={link.title}
                                            permissions={link.permissionsToShow}
                                        >
                                            <CheckSubRole
                                                subRoles={link.subRoleToShow}
                                            >
                                                <NavLink
                                                    isFullWidth
                                                    label={link.title}
                                                    to={link.path}
                                                />
                                            </CheckSubRole>
                                        </CheckPermissions>
                                    ))}
                                </StyledMenuDropdown>
                            </Menu>

                            <StyledSubitems isActive={isActive}>
                                {group.links.map((link) => (
                                    <CheckPermissions
                                        key={link.title}
                                        permissions={link.permissionsToShow}
                                    >
                                        <CheckSubRole
                                            subRoles={link.subRoleToShow}
                                        >
                                            <NavLink
                                                isFullWidth
                                                label={link.title}
                                                to={link.path}
                                                onClick={closeSideBar}
                                            />
                                        </CheckSubRole>
                                    </CheckPermissions>
                                ))}
                            </StyledSubitems>
                        </StyledMenuContent>
                    ))}
                </StyledMenuBlock>
            </StyledLeftMenu>
        </>
    );
};
