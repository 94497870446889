import { ReactElement } from "react";

import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { paths } from "lib/constants/paths";
import { Permission } from "lib/constants/permissions";
import { UserType } from "lib/constants/user-type";
import { toggleDeprecatedAnalytics } from "lib/toggle-deprecated-analytics";
import { ROOT_SUB_ROLES } from "lib/constants/root-sub-roles";

type ConfigType = {
    [key: string]: {
        name?: string;
        icon?: ReactElement;
        links: {
            title: string;
            path: string;
            permissionsToShow: Permission[];
            // If permissionsToShow is empty, show the field for all users.
            // If permissionsToShow contains specific permissions, show the field only for users with those permissions.
            subRoleToShow: string[];
            // If subRoleToShow is empty, show the field for all users.
            // If subRoleToShow contains specific subRole id, show the field only for users with those subRole.
        }[];
    }[];
};

export const generateTranslatedConfig = (
    t: (key: string, defaultValue: string) => string,
): ConfigType => ({
    [UserType.AGGREGATOR_USER]: [
        {
            name: t("common:leftMenu.label.userManagement", "USER MANAGEMENT"),
            icon: <Icon name={Icons.profileSvg} />,
            links: [
                {
                    title: t("common:leftMenu.button.users", "Users"),
                    path: paths.USERS,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
                {
                    title: t("common:leftMenu.button.roles", "Roles"),
                    path: paths.ROLES,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
                {
                    title: t(
                        "common:leftMenu.button.settingUpAccesses",
                        "Setting up accesses",
                    ),
                    path: paths.USERS_PERMISSIONS,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
            ],
        },
        {
            name: t("common:leftMenu.label.ipWhitelisting", "IP WHITELISTING"),
            icon: <Icon name={Icons.ipSvg} />,
            links: [
                {
                    title: t(
                        "common:leftMenu.button.clientList",
                        "Client list",
                    ),
                    path: paths.IP_CLIENTS,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
            ],
        },
        {
            name: t(
                "common:leftMenu.label.providerManagement",
                "PROVIDER MANAGEMENT",
            ),
            icon: <Icon name={Icons.leftMenuProviderManagementSvg} />,
            links: [
                {
                    title: t(
                        "common:leftMenu.button.providerCompanies",
                        "Provider companies",
                    ),
                    path: paths.PROVIDERS,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
                ...toggleDeprecatedAnalytics([
                    {
                        title: t(
                            "common:leftMenu.button.companyReports",
                            "Company reports",
                        ),
                        path: paths.PROVIDER_COMPANY_REPORTS,
                        permissionsToShow: [],
                        subRoleToShow: [],
                    },
                    {
                        title: t(
                            "common:leftMenu.button.standardReports",
                            "Standard reports",
                        ),
                        path: paths.STANDARD_REPORTS_PROVIDER,
                        permissionsToShow: [],
                        subRoleToShow: [],
                    },
                    {
                        title: t(
                            "common:leftMenu.button.foldersForReports",
                            "Folders for reports",
                        ),
                        path: paths.PROVIDER_FOLDERS_FOR_REPORTS,
                        permissionsToShow: [],
                        subRoleToShow: [],
                    },
                ]),
                {
                    title: t("common:leftMenu.button.games", "Games"),
                    path: paths.GAMES,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
                {
                    title: t(
                        "common:leftMenu.button.projectsForTesting",
                        "Projects for testing",
                    ),
                    path: paths.PROJECTS_FOR_TESTING,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
            ],
        },
        {
            name: t("common:leftMenu.label.monitoring", "MONITORING"),
            icon: <Icon name={Icons.monitoringSvg} />,
            links: [
                {
                    title: t(
                        "common:leftMenu.button.connections",
                        "Connections",
                    ),
                    path: paths.PLATFORM_MONITORING,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
                {
                    title: t(
                        "common:leftMenu.button.connectionRequests",
                        "Connection requests",
                    ),
                    path: paths.CONNECTION_REQUESTS,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
            ],
        },
        {
            name: t("common:leftMenu.label.b2bManagement", "B2B MANAGEMENT"),
            icon: <Icon name={Icons.leftMenuB2bManagementSvg} />,
            links: [
                {
                    title: t(
                        "common:leftMenu.button.b2bCompanies",
                        "B2B companies",
                    ),
                    path: paths.B2B_COMPANIES,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
                {
                    title: t(
                        "common:leftMenu.button.gameAccess",
                        "Game access",
                    ),
                    path: paths.GAME_ACCESS,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
                ...toggleDeprecatedAnalytics([
                    {
                        title: t(
                            "common:leftMenu.button.standardReports",
                            "Standard reports",
                        ),
                        path: paths.STANDARD_REPORTS_B2B,
                        permissionsToShow: [],
                        subRoleToShow: [],
                    },
                    {
                        title: t(
                            "common:leftMenu.button.companyReports",
                            "Company reports",
                        ),
                        path: paths.B2B_COMPANY_REPORTS,
                        permissionsToShow: [],
                        subRoleToShow: [],
                    },
                    {
                        title: t(
                            "common:leftMenu.button.foldersForReports",
                            "Folders for reports",
                        ),
                        path: paths.B2B_FOLDERS_FOR_REPORTS,
                        permissionsToShow: [],
                        subRoleToShow: [],
                    },
                ]),
                {
                    title: t(
                        "common:leftMenu.button.apiKeysList",
                        "API keys list",
                    ),
                    path: paths.API_KEYS,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
            ],
        },
        ...toggleDeprecatedAnalytics([
            {
                name: t("common:leftMenu.label.aggAnalytics", "AGG ANALYTICS"),
                icon: <Icon name={Icons.aggAnalyticsSvg} />,
                links: [
                    {
                        title: t(
                            "common:leftMenu.button.companyReports",
                            "Company reports",
                        ),
                        path: paths.AGGREGATOR_COMPANY_REPORTS_IN_FOLDERS_TREE,
                        permissionsToShow: [],
                        subRoleToShow: [],
                    },
                    {
                        title: t(
                            "common:leftMenu.button.standardReports",
                            "Standard reports",
                        ),
                        path: paths.STANDARD_REPORTS_AGG,
                        permissionsToShow: [],
                        subRoleToShow: [],
                    },
                    {
                        title: t(
                            "common:leftMenu.button.foldersForReports",
                            "Folders for reports",
                        ),
                        path: paths.AGG_FOLDERS_FOR_REPORTS,
                        permissionsToShow: [],
                        subRoleToShow: [],
                    },
                ],
            },
        ]),
        {
            name: t("common:leftMenu.label.analytics", "ANALYTICS"),
            icon: <Icon name={Icons.aggAnalyticsSvg} />,
            links: [
                {
                    title: t(
                        "common:leftMenu.button.reportList",
                        "Report list",
                    ),
                    path: paths.SUPERSET_REPORTS,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
                {
                    title: t(
                        "common:leftMenu.button.reportAccess",
                        "Report access",
                    ),
                    path: paths.USER_ACCESS_TO_SUPERSET_REPORTS,
                    permissionsToShow: [],
                    subRoleToShow: [ROOT_SUB_ROLES.AGGREGATOR_USER],
                },
            ],
        },
        {
            name: t("common:leftMenu.label.other", "OTHER"),
            icon: <Icon name={Icons.profileSvg} />,
            links: [
                {
                    title: t("common:leftMenu.button.handbooks", "Handbooks"),
                    path: paths.HANDBOOKS,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
            ],
        },
    ],
    [UserType.B2B_USER]: [
        {
            links: [
                {
                    title: t("common:leftMenu.button.startPage", "Start page"),
                    path: paths.STARTER_B2B,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
            ],
        },
        {
            name: t("common:leftMenu.label.userManagement", "USER MANAGEMENT"),
            icon: <Icon name={Icons.profileSvg} />,
            links: [
                {
                    title: t("common:leftMenu.button.users", "Users"),
                    path: paths.USERS,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
                {
                    title: t("common:leftMenu.button.roles", "Roles"),
                    path: paths.ROLES,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
                {
                    title: t(
                        "common:leftMenu.button.settingUpAccesses",
                        "Setting up accesses",
                    ),
                    path: paths.USER_ACCESS_COMPANIES,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
            ],
        },
        {
            name: t(
                "common:leftMenu.label.providerManagement",
                "PROVIDER MANAGEMENT",
            ),
            icon: <Icon name={Icons.leftMenuProviderManagementSvg} />,
            links: [
                {
                    title: t("common:leftMenu.button.games", "Games"),
                    path: paths.GAMES,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
                {
                    title: t(
                        "common:leftMenu.button.connections",
                        "Connections",
                    ),
                    path: paths.CONNECTIONS,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
            ],
        },
        {
            name: t(
                "common:leftMenu.label.companyManagement",
                "COMPANY MANAGEMENT",
            ),
            icon: <Icon name={Icons.leftMenuCompanyManagementSvg} />,
            links: [
                {
                    title: t(
                        "common:leftMenu.button.companyHierarchy",
                        "Company hierarchy",
                    ),
                    path: paths.B2B_COMPANIES_HIERARCHY,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
                {
                    title: t(
                        "common:leftMenu.button.apiKeysList",
                        "API keys list",
                    ),
                    path: paths.API_KEYS,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
            ],
        },
        {
            name: t("common:leftMenu.label.analytics", "ANALYTICS"),
            icon: <Icon name={Icons.aggAnalyticsSvg} />,
            links: [
                ...toggleDeprecatedAnalytics([
                    {
                        title: t("common:leftMenu.button.reports", "Reports"),
                        path: paths.B2B_ANALYTICS_REPORTS,
                        permissionsToShow: [],
                        subRoleToShow: [],
                    },
                    {
                        title: t(
                            "common:leftMenu.button.userAccesses",
                            "User accesses",
                        ),
                        path: paths.B2B_USER_ACCESS_TO_REPORTS,
                        permissionsToShow: [Permission.MANAGE_CLIENT_ANALYTICS],
                        subRoleToShow: [],
                    },
                    {
                        title: t(
                            "common:leftMenu.button.reportExports",
                            "Report exports",
                        ),
                        path: paths.REPORT_EXPORTS,
                        permissionsToShow: [],
                        subRoleToShow: [],
                    },
                ]),
                {
                    title: t(
                        "common:leftMenu.button.reportList",
                        "Report list",
                    ),
                    path: paths.SUPERSET_REPORTS,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
            ],
        },
    ],
    [UserType.PROVIDER]: [
        {
            name: t("common:leftMenu.label.userManagement", "USER MANAGEMENT"),
            icon: <Icon name={Icons.profileSvg} />,
            links: [
                {
                    title: t("common:leftMenu.button.users", "Users"),
                    path: paths.USERS,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
                {
                    title: t("common:leftMenu.button.roles", "Roles"),
                    path: paths.ROLES,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
            ],
        },
        {
            name: t("common:leftMenu.label.monitoring", "MONITORING"),
            icon: <Icon name={Icons.monitoringSvg} />,
            links: [
                {
                    title: t(
                        "common:leftMenu.button.connections",
                        "Connections",
                    ),
                    path: paths.PLATFORM_MONITORING,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
                {
                    title: t(
                        "common:leftMenu.button.connectionRequests",
                        "Connection requests",
                    ),
                    path: paths.CONNECTION_REQUESTS,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
                {
                    title: t("common:leftMenu.button.games", "Games"),
                    path: paths.GAMES,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
            ],
        },
        {
            name: t("common:leftMenu.label.analytics", "ANALYTICS"),
            icon: <Icon name={Icons.aggAnalyticsSvg} />,
            links: [
                ...toggleDeprecatedAnalytics([
                    {
                        title: t("common:leftMenu.button.reports", "Reports"),
                        path: paths.PROVIDER_ANALYTICS_REPORTS,
                        permissionsToShow: [],
                        subRoleToShow: [],
                    },
                    {
                        title: t(
                            "common:leftMenu.button.userAccesses",
                            "User accesses",
                        ),
                        path: paths.PROVIDER_USER_ACCESS_TO_REPORTS,
                        permissionsToShow: [Permission.MANAGE_CLIENT_ANALYTICS],
                        subRoleToShow: [],
                    },
                ]),
                {
                    title: t(
                        "common:leftMenu.button.reportList",
                        "Report list",
                    ),
                    path: paths.SUPERSET_REPORTS,
                    permissionsToShow: [],
                    subRoleToShow: [],
                },
            ],
        },
    ],
});
