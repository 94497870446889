import React from "react";

import { DropdownProps } from "./dropdown.interface";
import {
    StyledDropdown,
    StyledDropdownBlock,
    StyledErrorIconBlock,
    StyledErrorMessageContainer,
    StyledSelectContainer,
} from "./dropdown.styled";
import { colors } from "lib/palette";
import { IconBlock } from "./components/icon-block/icon-block.component";
import { EmptyItem } from "../empty-item/empty-item.component";
import { Tooltip } from "../tooltip/tooltip.component";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";

export const Dropdown = React.forwardRef<HTMLInputElement, DropdownProps>(
    (
        {
            data,
            placeholder,
            isFullWidth = true,
            isLoading,
            isDisabled,
            error,
            defaultValue,
            errorMessage,
            onChange,
            hideClearButton,
            fallbackBlock,
            showErrorIcon,
            zIndex = 1,
            testId,
            itemComponent,
            ...props
        },
        ref,
    ) => {
        return (
            <StyledDropdownBlock isFullWidth={isFullWidth}>
                <StyledSelectContainer>
                    <StyledDropdown
                        data-testid={testId}
                        dropdownPosition="bottom"
                        ref={ref}
                        zIndex={zIndex}
                        styles={() => ({
                            dropdown: {
                                padding: "10px 0px !important",
                                boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.1)",
                            },
                            item: {
                                borderRadius: 0,
                                fontSize: "13px",
                                lineHeight: "20px",
                                wordBreak: "break-all",
                                "&[data-selected]": {
                                    "&, &:hover": {
                                        backgroundColor: colors.blue_4,
                                        color: colors.black_1,
                                    },
                                },
                                // applies styles to hovered item (with mouse or keyboard)
                                "&[data-hovered]": {
                                    backgroundColor: colors.blue_4,
                                    color: colors.black_1,
                                },
                            },
                        })}
                        placeholder={placeholder}
                        $isFullWidth={isFullWidth}
                        rightSection={
                            <IconBlock
                                isDisabled={isDisabled}
                                isLoading={isLoading}
                            />
                        }
                        $showErrorIcon={error && showErrorIcon}
                        defaultValue={defaultValue}
                        onChange={onChange}
                        error={error}
                        disabled={!!isDisabled}
                        searchable
                        itemComponent={itemComponent}
                        {...(data?.length
                            ? { data }
                            : {
                                  itemComponent: EmptyItem,
                                  data: [
                                      {
                                          value: "",
                                          label: "",
                                          disabled: true,
                                          children: fallbackBlock,
                                      },
                                  ],
                              })}
                        {...props}
                    />
                    {!!error && showErrorIcon && (
                        <StyledErrorIconBlock>
                            <Tooltip
                                message={errorMessage}
                                svgComponent={<Icon name={Icons.errorSvg} />}
                            />
                        </StyledErrorIconBlock>
                    )}
                </StyledSelectContainer>
                {error && errorMessage && !showErrorIcon && (
                    <StyledErrorMessageContainer>
                        {errorMessage}
                    </StyledErrorMessageContainer>
                )}
            </StyledDropdownBlock>
        );
    },
);

Dropdown.displayName = "Dropdown";
