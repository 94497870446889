import styled from "styled-components";
import Link from "next/link";

import { colors } from "lib/palette";
import { StyledLinkBlockProps } from "./nav-link.interface";

export const LinkStyled = styled(Link)`
    width: 100%;
    height: max-content;
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    padding: 5px 20px 5px 17px;
    color: ${colors.black_1};
    text-decoration: none;
    border-left: 3px solid transparent;

    &.active {
        background: ${colors.blue_4};
        border-left: 3px solid ${colors.blue_2};
        color: ${colors.red_1};
    }
`;

export const StyledLinkBlock = styled.div<StyledLinkBlockProps>`
    width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "max-content")};
    height: max-content;

    &:hover {
        background: ${colors.blue_4};
    }
`;
