import styled, { css } from "styled-components";
import { Menu } from "@mantine/core";

import { LeftMenuProps } from "./left-menu.interface";
import { colors } from "lib/palette";
import { zIndexes } from "lib/z-indexes";

export const StyledLeftMenu = styled.div<LeftMenuProps>`
    position: fixed;
    width: ${({ isActive }) => (isActive ? "240px" : "64px")};
    height: 100vh;
    background: ${colors.white};
    border-right: 1px solid ${colors.blue_3};
    left: 0;
    top: 0;
    z-index: ${zIndexes.leftMenu};
    display: flex;
    flex-direction: column;
    transition: 0.4s;
    ${({ isMobile, isActive }) =>
        isMobile &&
        css`
            width: 240px;
            height: 100vh;
            transition: 0.4s;
            transform: ${isActive ? "translateX(0%)" : "translateX(-100%)"};
            z-index: ${zIndexes.leftMenu};
        `}
`;

export const StyledLogoContainer = styled.div<LeftMenuProps>`
    position: relative;
    cursor: pointer;
    padding-top: 13px;
    padding-left: 17px;
    padding-bottom: 15px;
    max-height: 60px;
    transition: 0.4s;
    border-bottom: 1px solid ${colors.blue_3};
    ${({ isActive }) =>
        !isActive &&
        css`
            padding: 10px 11.89px 25.12px 13px;
        `}

    > svg {
        position: static;
        max-width: 138px;
        transition: 0.4s;

        ${({ isActive }) =>
            !isActive &&
            css`
                max-width: 90px;
            `}
        path {
            &:first-child,
            &:nth-child(2) {
                width: 12px;
                transition: 0.4s;
                visibility: visible;
                opacity: 1;
                ${({ isActive }) =>
                    !isActive &&
                    css`
                        position: absolute;
                        top: -200px;
                        left: -200px;
                        z-index: ${zIndexes.hidElement};
                        max-width: 0;
                        width: 0;
                        overflow: hidden;
                        visibility: hidden;
                        opacity: 0;
                    `}
            }
        }
    }
`;

export const StyledMenuContent = styled.div<LeftMenuProps>`
    padding: 10px 0;
    transition: 0.3s;
    position: relative;
`;

export const StyledManagementBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 11px 20px;
    transition: 0.4s;
    position: relative;
    flex-wrap: nowrap;

    svg {
        min-height: 24px;
        min-width: 24px;
    }
`;

export const StyledManagementBlockText = styled.p<LeftMenuProps>`
    margin: 0;
    color: ${colors.black_1};
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 15px;
    visibility: visible;
    position: static;
    transition: 0.4s;
    opacity: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${({ isActive }) =>
        !isActive &&
        css`
            font-size: 0;
            transition: 0s;
            visibility: hidden;
            position: absolute;
        `}
`;

export const StyledManagementBlockDropdownText = styled.p`
    color: ${colors.black_1};
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 15px;
    padding: 5px 20px;
`;

export const StyledSubitems = styled.div<LeftMenuProps>`
    height: auto;
    opacity: 1;

    a {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    ${({ isActive }) =>
        !isActive &&
        css`
            height: 0;
            opacity: 0;
            visibility: hidden;
        `}
`;

export const StyledMobileBackgroundGrey = styled.div<LeftMenuProps>`
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    background-color: rgb(0 0 0 / 15%);
    z-index: ${zIndexes.leftMenuOverlay};
    ${({ isMobile, isActive }) =>
        isMobile &&
        css`
            display: ${isActive ? "block" : "none"};
        `};
`;

export const StyledMenuBlock = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden auto;
`;

export const StyledMenuDropdown = styled(Menu.Dropdown)`
    box-shadow: 0 2px 16px rgb(0 0 0 / 10%);
`;
