import { paths } from "lib/constants/paths";
import { UserType } from "lib/constants/user-type";
import { toggleDeprecatedAnalytics } from "lib/toggle-deprecated-analytics";

const pathsList = Object.values(paths);

type ConfigType = {
    [key in UserType]: {
        [index in (typeof pathsList)[number]]?: string;
    };
};

export const PAGE_INFO_CONFIG: ConfigType =
    process.env.APP_ENV === "production"
        ? {
              [UserType.AGGREGATOR_USER]: {},
              [UserType.PROVIDER]: {},
              [UserType.B2B_USER]: {
                  [paths.USERS]: "11243064113052",
                  [paths.ROLES]: "10118027609244",
                  [paths.USERS_PERMISSIONS]: "2345388006172",
                  [paths.CONNECTIONS]: "10114095352092",
                  [paths.GAMES]: "10171290830108",
                  [paths.B2B_COMPANIES_HIERARCHY]: "10678578094620",
                  ...toggleDeprecatedAnalytics({
                      [paths.B2B_ANALYTICS_REPORTS]: "12220960189212",
                  }),
              },
          }
        : {
              [UserType.AGGREGATOR_USER]: {},
              [UserType.PROVIDER]: {},
              [UserType.B2B_USER]: {
                  [paths.USERS]: "17159694252189",
                  [paths.ROLES]: "17160365792413",
                  [paths.USERS_PERMISSIONS]: "17160276527261",
                  [paths.CONNECTIONS]: "17160446110621",
                  [paths.GAMES]: "17160128546333",
                  [paths.B2B_COMPANIES_HIERARCHY]: "17159896816413",
                  ...toggleDeprecatedAnalytics({
                      [paths.B2B_ANALYTICS_REPORTS]: "17159474183837",
                  }),
              },
          };

export const MAIN_ARTICLES_PAGE_ID =
    process.env.APP_ENV === "production" ? "10119390304540" : "17159474183837";
